<template>
  <div style="width: 100%" class="mb-6">
    <template v-for="(option, i) in optionListSelection">
        <div :key="i">
          <div style="width: 100%" class="d-flex" >
            <p
                class="text-h5 text-center"
                style="color: #6495ed; width: 3%" :class="{ 'mt-4' : hasConfigPhonetic, 'mt-2' : !hasConfigPhonetic}">
              {{ i | getAlphabetCharacterFromIndex }}
            </p>
            <v-checkbox
                v-model="option.checked"
                class=""
                hide-details
            ></v-checkbox>
            <v-text-field
                :ref="'answer-in-any-order-'+i"
                dense outlined
                :label="'Option' + (i + 1)"
                v-model="option.value"
            ></v-text-field>
            <v-btn
                v-if="hasConfigPhonetic"
                outlined color="#039BE5"
                class="mt-0 ml-1" small
                @click="replacePinyinByTextSelectedViaInputOptionRef('answer-in-any-order-'+i, i)"
            >
              Thêm phiên âm
            </v-btn>
            <v-btn class="ml-1" @click="deleteListSelection(i)" small outlined color="red">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </div>
          <div style="width: 100%" v-if="hasConfigPhonetic" class="d-flex mb-8">
            <div style="width: 5.5%" class="p-0"></div>
            <div style="border: 1px solid grey; width: 95%" class="rounded">
              <p class="subtitle-1 mb-0 border-bottom pl-1" style="background-color: #8080806e">Preview</p>
              <div
                  class="rounded px-1 py-2 container-pinyin-preview"
                  v-html="option.value">
              </div>
            </div>
          </div>
        </div>
    </template>
    <div style="width: 100%">
      <v-btn large @click="addOptionListSelection">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import phoneticSupport from "../Support/Question/phoneticSupport";

export default {
  name: "AnswerInAnyOrder",
  mixins: [
    phoneticSupport,
  ],
  props: {
    optionListSelectionDataProp: {
      type: Array,
      default: () => [{ value: ""}, { value: ""}],
    },
    hasConfigPhonetic: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    is_call_api: {
      get() {
        return this.$store.state.informationTestBuilderStore.is_call_api;
      },
      set(value) {
        this.$store.commit("informationTestBuilderStore/setIsCallApi", value);
      },
    },
    optionListSelection: {
      get() {
        return this.optionListSelectionDataProp;
      },
      set(value) {
        this.$emit("setOptionListSelection", value);
      },
    },
  },
  methods: {
    messError(mess = '') {
      this.$toasted.error(mess, {theme: "toasted-primary", position: "top-right", duration: 4000,});
    },
    deleteListSelection(i) {
      if (this.optionListSelection.length <= 1) {
        this.messError('Phải có tối thiểu 1 question');
        return;
      }
      this.optionListSelection.splice(i, 1);
    },
    addOptionListSelection() {
      let data = {
        value: "",
        checked: false,
      };
      this.optionListSelection.push(data);
    },
    async replacePinyinByTextSelectedViaInputOptionRef(inputRef = '', indexOption) {
      if (!inputRef) {
        this.messError('Có lỗi ! Hãy báo bên kỹ thuật');
        return;
      }
      let refOption = this.$refs[inputRef][0].$refs;
      let input = refOption.input;

      const textSelected = input.value.substring(
          input.selectionStart,
          input.selectionEnd,
      );

      if (!textSelected) {
        this.messError('Chưa chọn vùng text để lấy pinyin');
        return;
      }

      const starSto = input.value.substring(0, input.selectionStart);
      const endSto = input.value.substring(input.selectionEnd, input.value.length);

      const dataPinyin = await this.getDataPinyinByTextSelected(textSelected);
      if (!dataPinyin) return;

      this.optionListSelection[indexOption].value =
          starSto +
          " " + dataPinyin + " " +
          endSto
      ;

      setTimeout(() => {
        input.focus();
        input.selectionStart = starSto.length + dataPinyin.length +2;
        input.selectionEnd = starSto.length + dataPinyin.length +2;
      }, 10);

    },
  }
}
</script>

<style scoped>
.container-pinyin-preview {
  height: auto;
  min-height: 40px;
  font-size: 28px;
  line-height: normal;
}
</style>